* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loginBg {
  /* background-image: url(./images/90CC5FD7-FD24-4669-9220-86ED74046D10_cx0_cy16_cw0_w1023_r1_s\ 1.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; */
  background-color: rgba(208, 221, 240, 1);
  background-image: linear-gradient(
    45deg,
    #ffffff36 0%,
    rgba(127, 162, 182, 0.688) 40%,
    #ffffff36 80%,
    rgba(127, 162, 182, 0.688) 100%,
    #ffffff36 100%
  );
}

.loginDiv {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}
.svgIConShowHide {
  cursor: pointer;
  content: "";
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 50%;
  transition: all 0.5s;
  z-index: 1;
  font-size: 30px;
}
